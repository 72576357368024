import React from 'react';
import { Box, Typography, Paper, Grid, Alert, Modal, CardContent } from '@mui/material';

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';



import Image from 'mui-image'
import ReactMarkdown from 'react-markdown';
import { useQuery } from "react-query";
import axios from 'axios';
import CommandCard from './CommandItem';
import {API_URL, POLLING_TIME} from "../../env";

const errorModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const url=API_URL;

const queryParams = new URLSearchParams(window.location.search);
var code=queryParams.get('cid');
console.log(code);

export default function MainPage() {
    const [test, setTest] = React.useState(false);
    const [codeSetted, setCodeSetted] = React.useState(false);
    const [codeNotVaid, setCodeNotVaid] = React.useState(false);
    const [markdownDescription, setMarkdownDescription] = React.useState('');
    const [commands, setCommands] = React.useState('');
    const [codeModalOpen, setCodeModalOpen] = React.useState(false);
    const [errorModalOpen, setErrorModalOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);

    var lastUpdate=0;

    const updateState = React.useCallback(async () => {
        if(code){
            try {
                var response = await fetch(url+'buGetLastUpdate?code='+code);
                var data = null;
        
                data = await response.json();
                if(data?.error){
                    return;
                }

                if(!(data)?.last_update){
                    setCodeNotVaid(true);
                } else {
                    setCodeNotVaid(false);
                }
                
                var last = new Date((data)?.last_update ?? 0);

                if(last>lastUpdate){
                    response = await fetch(url+'buGetCommand?code='+code);
                    data = await response.json();
                    setCommands(data?.commands ?? '[]');
                    setCodeSetted(true);
                    lastUpdate=last;
                }
            } catch (error) {
                console.log(error.message);
            }
        }
    }, []);

    React.useEffect(() => {
        updateState();
        setInterval(updateState, POLLING_TIME);
    }, [updateState]);

    
    React.useEffect(() => {
        axios.get('/markdown/aiProcessAutomation.md')
        .then((response) => {
        setMarkdownDescription(response.data);
        })
        .catch((error) => {
        console.error('Errore nel recupero del file Markdown', error);
        });  
    }, []);

    const handleClickOpenCodeModal = () => {
        setCodeModalOpen(true);
    };
    
      const handleCodeModalClose = () => {
        setCodeModalOpen(false);
    };

    const handleCodeCodeSetted = () => {
        setCodeSetted(true);
        setCodeModalOpen(false);
    }
    

    return(<>

<       Box sx={{width: "100%", minHeight: '70vh', paddingTop: 2, paddingBottom:2, margin:0, display: 'flex', flexDirection: 'column'}} >  
            <Paper sx={{ spacing: 2, padding: 2 } }  >    
            <Grid container spacing={2}
                alignItems="center"
                justify="center"
                style={{ }}>

                <Grid item xs={12} sm={"auto"}>
                    <Image src={`${process.env.PUBLIC_URL}/img/AiProcessAutomation.jpg`} shift="left" style={{ maxHeight:250, maxWidth:250, borderRadius: 16 }} />   
                </Grid>
                <Grid item xs={12} sm={9}>
                    <Typography variant="body1" color="text.secondary">
                    <ReactMarkdown>{markdownDescription}</ReactMarkdown>
                    </Typography>
                </Grid>

            
            </Grid>
            </Paper>
            <Box m={1} />
        {(!codeSetted || codeNotVaid)?(<>
            {(codeNotVaid && !codeModalOpen) && (
                <Typography variant="body1" sx={{width: "70%", alignSelf: "center"}}>
                The code provided is not valid, please check on the Telegram chat and enter the correct code
                </Typography>
            )}
            
            <Button variant="outlined" onClick={handleClickOpenCodeModal} sx={{width: "70%", alignSelf: "center"}}>
                Enter the code received from the Telegram channel
            </Button>


        </>):(<>
            <Alert severity="success" >New Commands Received</Alert>
            <Box m={1} />

            {commands && (
            <Grid container spacing={4}>
                {commands.map((n,index) => (
                <Grid item key={"ex"+index} xs={12} >
                    <CommandCard key={"pdt"+index}  {...n}></CommandCard>
                </Grid>
                ))}
            </Grid>
            )}
        </>)}
        </Box>

        <Modal
            open={errorModalOpen}
            onClose={()=>(setErrorModalOpen(false))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={errorModalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Error
                </Typography>
                <Typography id="modal-modal-description" sx={{ mz: 2 }}>
                    {errorMessage}
                </Typography>
            </Box>
        </Modal>
        <Dialog open={codeModalOpen} onClose={handleCodeModalClose}>
            <DialogTitle>Subscribe</DialogTitle>
            <DialogContent>
            <DialogContentText>
            To access, you must first enter at least one command by sending a voice message to the Telegram channel. 
            At the end of the response via Telegram, you will receive the code to view the commands on the website
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Enter the code here"
                type="text"
                onChange={(event) => {code=(event.target.value.trim())}}
                fullWidth
                variant="standard"
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCodeModalClose}>Cancel</Button>
            <Button onClick={handleCodeCodeSetted}>Subscribe</Button>
            </DialogActions>
        </Dialog>
    </>)
}
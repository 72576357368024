import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import {TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseArrowIcon from '@mui/icons-material/PauseCircle';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ClearIcon from '@mui/icons-material/Clear';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Switch, Case} from "../../lib/Switch";
import moment from "moment";
import {API_URL} from "../../env";

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}
  
const useAudio = url => {
    const [audio] = React.useState(new Audio(url));
    const [playing, setPlaying] = React.useState(false);
  
    const toggle = () => setPlaying(!playing);
  
    React.useEffect(() => {
        playing ? audio.play() : audio.pause();
      },
      [playing]
    );
  
    React.useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  
    return [playing, toggle];
};



export default function CommandCard(n) {
    const [playing, toggle] = useAudio("https://news.bazzify.com/audio_command/"+n.audio_file);
    const [show, setShow] = React.useState(true);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogTitle, setDialogMessage] = React.useState("");
    const [dialogText, setDialogText] = React.useState("");
    const [status,setStatus] = React.useState(n.status);
    
    
    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    
    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    
    function handleChangeStatusAction (id, newStatus) {
        setStatus(newStatus);
        switch (newStatus) {
            case "run":
                setDialogMessage("Are you sure you want to execute the command?");
                setDialogText("This application is a demo and is not connected to any devices or services; executing the command has no effects on other systems.");
                handleDialogOpen();
                break;
            case "delete":
                setDialogMessage("Are you sure you want to execute the command?");
                setDialogText("The command will be removed only from the display; in future versions, you will be able to view the command history through a dedicated feature.");
                handleDialogOpen();
                break;    
            default:
                break;
        }

    }

    function handleChangeStatus () {
        try {
            fetch(API_URL+'buUpdateStatus?id='+n.id+'&status='+status);
            setShow(false);
        } catch (error) {
            console.log(error.message);
        }
        handleDialogClose();
    }


    return (show &&( <>
        <Paper
            sx={{
                p: 2,
                margin: 'auto',
                width: "100%",
                flexGrow: 1,
                backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
            <Grid container spacing={2}>

                <Grid item xs={12} sm container>
                    <Box sx={{ display: 'flex', flexDirection: 'row', width:"100%", alignItems: "center" }}>
                        <Avatar {...stringAvatar(n.fulluser)} sx={{ margin:2}} />
                        <Typography  variant="body1" component="h2" sx={{ width:"100%", alignItems:"flex-start"}}>
                            {moment.utc(n.created_at).fromNow()}
                        </Typography>
                        
                        {n.audio_file && (
                        <IconButton aria-label="play/pause" sx={{  alignItems:"flex-end"}}>
                            {!playing?(
                                <PlayArrowIcon onClick={toggle} sx={{ height: 38, width: 38 }} />
                            ):(
                                <PauseArrowIcon onClick={toggle} sx={{ height: 38, width: 38 }} />
                            )}
                        </IconButton>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <Typography variant="body1" component="h2">
                            {n.trascript}
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', width:"100%", alignItems: "center" }}>
                        <IconButton aria-label="run" >
                            <DirectionsRunIcon onClick={() => {handleChangeStatusAction(n.id,"run")}} sx={{ height: 38, width: 38 }} />
                        </IconButton>
                        <IconButton aria-label="delete" >
                            <ClearIcon onClick={() => {handleChangeStatusAction(n.id,"delete")}} sx={{ height: 38, width: 38 }} />
                        </IconButton>
                    </Box>
                </Grid>

                <Grid item xs={12} sm container >
                    <Typography variant="body1" component="h2">
                    {n.command.operation} {n.command.commandType} for {n.command.customer}
                    </Typography>
                    <Typography variant="body1" component="h2">
                        <Switch test={n.command?.commandType??"default"}>
                        <Case value="order">
                            <TableContainer component={Paper} >
                                <Table sx={{width:"100%"}} aria-label="simple table">
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Product</TableCell>
                                        <TableCell align="right">Quantity</TableCell>
                                        <TableCell align="right">Units</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {n.command.products && n.command.products.map((row,index) => (
                                        <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                        <TableCell component="th" scope="row">
                                            {row.product}
                                        </TableCell>
                                        <TableCell align="right">{row.quantity}</TableCell>
                                        <TableCell align="right">{row.unit}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Case>
                        <Case default>
                            Command is not yet handled automatically.
                        </Case>
                        </Switch>


                    </Typography>
                </Grid>
            </Grid>
        </Paper>

        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {dialogTitle}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {dialogText}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleDialogClose}>Discard</Button>
            <Button onClick={handleChangeStatus} autoFocus>
                Confirm
            </Button>
            </DialogActions>
        </Dialog>
      </>));
}